import React, { FC } from 'react';
import { QuoteType } from '../../../models/quote-type';

const Quote: FC<QuoteType> = ({ quote, author, jobDescription }: QuoteType) => {
  const attribution =
    `${author}` +
    `${author && jobDescription ? ' - ' : ''}` +
    `${jobDescription}`;

  return (
    <div className="py-component-spacing-mobile lg:py-component-spacing-content flex mx-auto w-full md:w-10/12 lg:w-8/12">
      <div className="flex-shrink-0 my-1 mr-6 w-1 bg-french-blue rounded-sm" />
      <figure>
        <blockquote className="page-heading-four">{`"${quote}"`}</blockquote>
        {!!attribution && (
          <figcaption className="mt-3 text-grey-600">{attribution}</figcaption>
        )}
      </figure>
    </div>
  );
};

export default Quote;
